import boqAttributes from './boqAttributes';
import uploadExcel from '@/app/components/shared/uploadExcel';
import URL_UTILITY from '../../../../utility/url.utility';

export default {
  name: 'boq',
  props: ['rfphdrid', 'headerAllData', 'boqAllData', 'negotiationAllData'],
  components: {
    boqAttributes,
    uploadExcel
  },
  data() {
    return {
      boqLineCode: null,
      description: null,
      quantity: null,
      uom: null,
      loading: false,
      showOpenBoqAttributeModal: false,
      boqData: [
        // {
        //   boq_line_code: null,
        //   boq_long_description: null,
        //   description: null,
        //   quantity: null,
        //   uom: null,
        //   boq_id: 0,
        //   delete_flag: false
        // }
      ],
      boqFields: [
        // {
        //   key: 'add_row',
        //   label: 'Add',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        // {
        //   key: 'delete_flag',
        //   label: 'Delete',
        //   class: 'text-center'
        // },
        // {
        //   key: 'remove_row',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        // {
        //   key: 'r_number',
        //   label: 'Row Number',
        //   class: 'text-center'
        // },
        {
          key: 'sheet_name',
          label: 'Sheet Name',
          class: 'text-left col-fix-small'
        },
        {
          key: 's_number',
          label: 'Serial No.',
          class: 'text-left col-fix-small'
        },
        {
          key: 'boq_line_code',
          label: 'Activity Code'
        },
        {
          key: 'boq_line_subcode',
          label: 'Subcode'
        },
        {
          key: 'Description',
          class: 'col-fix-extra-large'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-left col-fix-small'
        },
        {
          key: 'quantity',
          class: 'text-left col-fix-small'
        }
        // {
        //   key: 'boq_long_description',
        //   label: 'BOQ Specification',
        //   class: 'text-center'
        // },
        // {
        //   key: 'add_attribute',
        //   label: 'Add Attribute',
        //   class: 'text-center'
        // }
      ],
      rfpHeaderId: null,
      responseMsg: null,
      editMode: true,
      rfpInitiateBidFlag: false,
      itemBasis: null,
      boqId: null,
      rfpType: null,
      rfpClosedFlag: false,
      rfpBoqFlag: false,
      showUploadExcel: false,
      boqIndex: null,
      downloadRfpItemDocumentUrl: URL_UTILITY.getRfpBoqSampleFile,
      popoverContent: null,
      showOpenLongDescriptionModal: false,
      boqLongDescription: null,
      longDescriptionIndex: null,
      uploadDocList: [],
      showUpdateUploadExcel: false,
      openkmAuthToken: null
    };
  },
  mounted() {
    if (this.rfphdrid) {
      this.rfpHeaderId = this.rfphdrid;
      this.getBoqData();
    }
    if (this.boqAllData && this.boqAllData.length > 0) {
      this.rfpBoqFlag = true;
      this.editMode = false;
    } else {
      this.rfpBoqFlag = false;
    }
    this.eventBus.$emit('rfpBoqFlag', this.rfpBoqFlag);
    if (this.headerAllData) {
      this.itemBasis = this.headerAllData.rfp_basis;
      if (this.itemBasis === 'item') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      }
    }
    if (this.negotiationAllData && this.negotiationAllData.length > 0) {
      this.rfpClosedFlag = true;
    }
    this.getBoqExcelDocuments();
  },
  created() {
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('itemBasis', itemBasis => {
      this.itemBasis = itemBasis;
      if (this.itemBasis === 'item') {
        this.rfpInitiateBidFlag = true;
        this.editMode = false;
      } else if (this.itemBasis === 'BOQ') {
        this.rfpInitiateBidFlag = false;
      }
    });
    this.eventBus.$on('boqAttributeDataFlag', boqAttributeDataFlag => {
      if (boqAttributeDataFlag) {
        this.$emit('getHeaderData');
      }
    });
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpType', rfpType => {
      this.rfpType = rfpType;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
    });
  },
  methods: {
    addNewRow() {
      // this function adds a new row on click of add button inside the table
      this.boqData.push({
        boq_line_code: null,
        Description: null,
        quantity: null,
        uom: null,
        boq_id: 0,
        delete_flag: false
      });
    },
    removeRow(index) {
      // this function removes the row on click of add button inside the tables
      this.boqData.splice(index, 1);
    },
    postBoqData() {
      let boqdetails = [];
      let selectQuantityCounter = 0;
      let quantityGreaterThanZeroCounter = 0;
      let boqDescriptionCounter = 0;
      let boqLineCodeCounter = 0;
      for (let i of this.boqData) {
        if (
          (i.quantity === null || i.quantity === '') &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          selectQuantityCounter = selectQuantityCounter + 1;
        } else if (
          (i.quantity === 0 || i.quantity < 0) &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          quantityGreaterThanZeroCounter = quantityGreaterThanZeroCounter + 1;
        } else if (
          (i.Description === null || i.Description === '') &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          boqDescriptionCounter = boqDescriptionCounter + 1;
        } else if (
          (i.boq_line_code === null || i.boq_line_code === '') &&
          (this.rfpType === 'NRC_OE' || this.rfpType === 'NRC_CE') &&
          !i.delete_flag
        ) {
          boqLineCodeCounter = boqLineCodeCounter + 1;
        } else {
          boqdetails.push({
            boq_id: i.boq_id,
            boq_line_code: i.boq_line_code,
            boq_description: i.Description,
            boq_long_description: i.boq_long_description,
            boq_quantity: i.quantity,
            boq_uom: i.uom,
            delete_flag: i.delete_flag,
            r_number: +i.r_number,
            s_number: +i.s_number,
            boq_line_subcode: i.boq_line_subcode
          });
        }
      }

      if (boqLineCodeCounter > 0) {
        alert('Please fill BOQ Line Code');
      } else if (boqDescriptionCounter > 0) {
        alert('Please fill BOQ description');
      } else if (selectQuantityCounter > 0) {
        alert('Please select Quantity');
      } else if (quantityGreaterThanZeroCounter > 0) {
        alert('Quantity should be greater than zero');
      } else if (boqdetails.length > 0) {
        let payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_boq_dtls: boqdetails
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postBoqDetails', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getBoqData();
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
            }
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },
    getBoqData() {
      const payload = {
        header_id: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getBoqDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.boqData = response.data.data;
            for (let i = 0; i < this.boqAllData.length; i++) {
              this.boqData[i].rfp_boq_attribute_dtls = this.boqAllData[
                i
              ].rfp_boq_attribute_dtls;
            }
            // if (this.boqData.length === 0) {
            //   setTimeout(() => {
            //     this.boqData.push({
            //       boq_line_code: null,
            //       boq_long_description: null,
            //       Description: null,
            //       quantity: null,
            //       uom: null,
            //       boq_id: 0,
            //       delete_flag: false
            //     });
            //     this.rfpBoqFlag = false;
            //     this.editMode = true;
            //   }, 500);
            // } else {
            //   this.rfpBoqFlag = true;
            //   this.editMode = false;
            // }
            // this.eventBus.$emit('rfpBoqFlag', this.rfpBoqFlag);
            setTimeout(() => {
              this.boqData = JSON.parse(JSON.stringify(this.boqData));
            }, 500);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    deleteItem(item) {
      item.delete_flag = !item.delete_flag;
    },
    boqAttributeModal(flag) {
      this.showOpenBoqAttributeModal = flag;
    },
    openAttributesModal(id, index) {
      if (id === 0) {
        alert('Please save BOQ first!');
      } else {
        this.boqId = id;
        this.boqIndex = index;
        this.showOpenBoqAttributeModal = true;
      }
    },
    qtyTwoDecimalValue(index) {
      let quantity = this.boqData[index].quantity.toString().split('.');
      let withoutDecimal = this.boqData[index].quantity.toString();
      if (quantity.length > 1 && quantity[0].length > 14) {
        this.boqData[index].quantity = null;
        alert('Only Four Decimal Value Allowed.');
      } else if (quantity.length > 1 && quantity[1].length > 5) {
        this.boqData[index].quantity = parseFloat(
          this.boqData[index].quantity
        ).toFixed(5);
        alert('Only Five Decimal Value Allowed.');
      } else if (withoutDecimal.length > 14) {
        this.boqData[index].quantity = null;
        alert('Only Fourteen digits allowed before decimal');
      }
    },
    showHideUploadExcel(flag) {
      if(this.boqData.length === 0) {
        this.showUploadExcel = flag;
      } else {
        this.showUpdateUploadExcel = flag;
      }
      
    },
    closeUploadExcelModal() {
      this.getBoqData();
      this.getBoqExcelDocuments();
      this.showUploadExcel = false;
      this.showUpdateUploadExcel = false;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: this.$store.state.shared.menuId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    hideLongDescriptionModal() {
      this.showOpenLongDescriptionModal = false;
    },
    openLongDescriptionModal(index) {
      this.boqLongDescription = this.boqData[index].boq_long_description;
      this.longDescriptionIndex = index;
      this.showOpenLongDescriptionModal = true;
    },
    addBoqLongDescription() {
      this.boqData[
        this.longDescriptionIndex
      ].boq_long_description = this.boqLongDescription;
      this.showOpenLongDescriptionModal = false;
    },
    getBoqExcelDocuments() {
      const payload = {
        _page: 0,
        _limit: 1000,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 90,
        record_id: this.rfpHeaderId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              this.uploadDocList = response.data.data.page;
              this.docDetailId = response.data.data.page[0].doc_detail_id;
              this.rfpBoqFlag = true;
              this.eventBus.$emit('rfpBoqFlag', this.rfpBoqFlag);
            }
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'danger');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    confirmUpdateExcel(flag) {
      if(flag === 'no') {
        this.showHideUploadExcel(false);
      } else {
        this.deleteUploadedExcel()
        this.showUploadExcel = true;
      }
    },
    deleteUploadedExcel() {
      const payload = {
        rfp_header_id: this.rfpHeaderId
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/deleteExcelUploadBoq', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.getOpenKmAuthTokenUploadDocument();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteExcelDocument() {
      const payload = {
        docDetailId: this.docDetailId,
        token: this.openkmAuthToken
      };
      if (this.docDetailId !== 0) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/delteOpenKmDoc', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              alert('Document Deleted Successfully!');
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getOpenKmAuthTokenUploadDocument() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            if (this.openkmAuthToken) {
              this.deleteExcelDocument();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.eventBus.$off('rfpBoqFlag');
  }
};
