import commonHelper from '@/app/utility/common.helper.utility';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
export default {
  name: 'backgroundVerification',
  components: {
    UploadDocument
  },
  data() {
    return {
      loader: false,
      bgvcData: [],
      bgvcfields: [
        {
          key: 'name',
          class: 'text-center'
        },
        {
          key: 'email',
          class: 'text-center'
        },
        {
          key: 'mobile_no',
          label: 'Mobile',
          class: 'text-center'
        },
        {
          key: 'pan_no',
          label: 'PAN No.',
          class: 'text-center'
        },
        {
          key: 'address',
          class: 'col-fix-sm'
        },
        {
          key: 'director',
          class: 'col-fix-sm'
        },
        {
          key: 'status',
          class: 'text-center'
        },
        {
          key: 'action',
          class: 'col-fix-sm'
        },
        {
          key: 'upload_document',
          class: 'text-center'
        }
      ],
      showOpenAddressModal: false,
      name: null,
      email: null,
      mobile: null,
      pan: null,
      currentPage: 1,
      totalRows: null,
      pageOptions: commonHelper.getPageOption(),
      perPage: 10,
      regAddress: {
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      siteAddress: {
        add1: null,
        add2: null,
        country: null,
        state: null,
        city: null,
        pin: null
      },
      showHistory: false,
      historyType: null,
      selectedGridData: {
        le_id: '67',
        le_name: 'Delhivery Limited',
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      userId: null,
      bgvcHdrId: null,
      isSuccess: false,
      closeFlag: false,
      menuId: null,
      showOpenDirectorModal: false,
      directorData: [],
      directorFields: [
        {
          key: 'bgvc_dir_name',
          label: 'Name',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_pan_no',
          label: 'PAN',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_email',
          label: 'Email',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_address',
          label: 'Address',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_phone',
          label: 'Phone',
          class: 'text-center'
        },
        {
          key: 'bgvc_dir_father_name',
          label: "Father's Name",
          class: 'text-center'
        }
      ],
      showDirectors: false,
      bgvcHdrIdForDirectors: null
    };
  },
  watch: {
    currentPage: function() {
      this.getBackgroundVerificationData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBackgroundVerificationData();
    }
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.menuId = this.$store.state.shared.menuId;
  },
  methods: {
    addressModal(flag) {
      this.showOpenAddressModal = flag;
    },
    openAddressModal(hdrId) {
      this.getAddressData(hdrId);
      this.showOpenAddressModal = true;
    },
    directorModal(flag) {
      this.showOpenDirectorModal = flag;
    },
    openDirectorModal(hdrId) {
      this.getAddressData(hdrId);
      this.showOpenDirectorModal = true;
      this.bgvcHdrIdForDirectors = hdrId;
    },
    getBackgroundVerificationData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        name: this.name,
        mobile: this.mobile,
        email: this.email,
        pan: this.pan
      };
      this.loader = true;
      this.bgvcData = [];
      this.$store
        .dispatch('intWorkflow/getBgvcData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.bgvcData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateBackgroundVerificationData(hdrId) {
      const payload = {
        BGVC_HDR_ID: hdrId
      };
      this.loader = true;
      this.bgvcData = [];
      this.$store
        .dispatch('intWorkflow/updateBgvcData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.getBackgroundVerificationData();
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getAddressData(hdrId) {
      const payload = {
        bgVcHdrId: hdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getBackgroundVerificationDetailsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let result = response.data.data.bgvc_dtls;
            for (let i of result) {
              if (i.address_type === 'REG') {
                this.regAddress = {
                  add1: i.address1,
                  add2: i.address2,
                  country: i.country,
                  state: i.state,
                  city: i.city,
                  pin: i.pincode
                };
              } else if (i.address_type === 'SITE') {
                this.siteAddress = {
                  add1: i.address1,
                  add2: i.address2,
                  country: i.country,
                  state: i.state,
                  city: i.city,
                  pin: i.pincode
                };
              }
            }
            this.directorData = response.data.data.bgvc_dir_dtls;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthTokenUploadDocument(hdrId) {
      this.bgvcHdrId = hdrId;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideHistory(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    getUploadedDocDtl(hdrId) {
      const payload = {
        _page: 0,
        _limit: 100,
        menu_id: this.menuId,
        sub_menu_id: 0,
        record_id: hdrId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.closeBackgroundVerificationData(hdrId);
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closeBackgroundVerificationData(hdrId) {
      const payload = {
        BGVC_HDR_ID: hdrId
      };
      this.loader = true;
      this.bgvcData = [];
      this.$store
        .dispatch('intWorkflow/closeBgvc', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.getBackgroundVerificationData();
            this.responseMsg = response.data.data;
            this.makeToast(this.responseMsg, 'success');
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast'
      });
    },
    clearData() {
      this.name = null;
      this.email = null;
      this.mobile = null;
      this.pan = null;
    },
    getOpenKmAuthTokenUploadDocumentForDirectors() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.showHideDirectors(true, 'invoice');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideDirectors(flag, historyType) {
      this.showDirectors = flag;
      this.historyType = historyType;
    },
  }
};
