import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'updateEnergyDataInput',
  props: ['rowData'],
  components: { DatePicker },
  watch: {
    currentPage: function() {
      this.getcrystalBillUploadById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getcrystalBillUploadById();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      defaultValue: {
        text: null,
        value: null
      },
      batchName: null,
      batchHdrId: null,
      projectName: null,
      billingPeriodFrom: null,
      billingPeriodTo: null,
      batchStatus: null,
      batchRemark: null,
      statusMsg: null,
      crystalInputDtlsData: [],
      crystalInputDtlsFields: [
        {
          key: 'bill_number'
        },
        {
          key: 'meter_no'
        },
        {
          key: 'bill_date_from'
        },
        {
          key: 'bill_date_to'
        },

        {
          key: 'kvah_previous_grid_reading'
        },
        {
          key: 'kvah_current_grid_reading'
        },
        {
          key: 'kwh_previous_grid_reading'
        },
        {
          key: 'kwh_current_grid_reading'
        },
        {
          key: 'dg_previous_grid_reading'
        },
        {
          key: 'dg_current_grid_reading'
        },
        {
          key: 'unit_consumed_mains'
        },
        {
          key: 'unit_consumed_dg'
        },
        {
          key: 'free_units_mains'
        },
        {
          key: 'free_units_dg'
        },
        {
          key: 'interest'
        },
        {
          key: 'adjustment'
        },
        {
          key: 'adjustment_remark'
        },
        {
          key: 'recharge_amount'
        },
        {
          key: 'last_month_balance'
        },
        {
          key: 'monthly_total_amount'
        },
        {
          key: 'billing_slab_amount'
        },
        {
          key: 'load_sanction_main'
        },
        {
          key: 'load_sanction_dg'
        },
        {
          key: 'credit_limit'
        },
        {
          key: 'unit_name'
        },
        {
          key: 'unit_address'
        },
        {
          key: 'unit_area'
        },
        {
          key: 'billing_address'
        },
        {
          key: 'user_full_name'
        },
        {
          key: 'billing_slab_amount'
        },
        {
          key: 'dg_slab_amount'
        },
        {
          key: 'meter_threshold_value'
        },
        {
          key: 'interest'
        },
        {
          key: 'balance_before_interest'
        },
        {
          key: 'gst_on_interest'
        },
        {
          key: 'max_demand'
        },
        {
          key: 'mains_amount'
        },
        {
          key: 'fix_charges_on_san_load_mains'
        },
        {
          key: 'maintenance_charges_sac_hsn_code_995419'
        },
        {
          key: 'common_area_elect_charges_sac_hsn_code_995419'
        },
        {
          key: 'server_rent_sac_hsn_code_995419'
        },
        {
          key: 'dg_charges_sac_hsn_code_995419'
        },
        {
          key: 'water_charges'
        },
        {
          key: 'cgst_9'
        },
        {
          key: 'sgst_9'
        },
        {
          key: 'charge_1'
        },
        {
          key: 'charge_2'
        },
        {
          key:'dg_status'
        },
        {
          key:'elec_status'
        },
        {
          key:'oth_status'
        }
      ],
      showValueSetModal: false,
      unsubscribe: null,
      editMode: false,
      specIndex: null,
      payload: null,
      showExcelUploadModal: false,
      templateId: null,
      requestId: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      requestStatus: null,
      variantType: null,
      requestStatus1: null,
      unitType: null,
      vsetCode: null,
      meterNumber: null,
      billNumber: null,
      dgStatus: {
        text: null,
        value: null
      },
      electricityStatus: {
        text: null,
        value: null
      },
      othersStatus: {
        text: null,
        value: null
      },
      statusType:null,
      crystalBillFields:[
        {
          key:'base_amount',
          class:'text-center'
        },
        {
          key:'pos',
          class:'text-center'
        },
        {
          key:'fms_comp_display_name',
          class:'text-center'
        },
        {
          key:'fms_prj_code',
          class:'text-center'
        },
        {
          key:'sac_code',
          class:'text-center'
        },
        {
          key:'customer_name',
          class:'text-center'
        },
        {
          key:'created_by',
          class:'text-center'
        },
        {
          key:'last_updated_by',
          class:'text-center'
        },
        {
          key:'creation_date',
          class:'text-center'
        },
        {
          key:'rate',
          class:'text-center'
        },
        {
          key:'qty',
          class:'text-center'
        },
        {
          key:'amount',
          class:'text-center'
        },
        {
          key:'actual_rate',
          class:'text-center'
        },
        {
          key:'status',
          class:'text-center'
        },
        {
          key:'fms_prj_name',
          class:'text-center'
        },
        {
          key:'bill_cat_vset',
          class:'text-center',
          label:'Bill Category'
        },
        {
          key:'bill_type_vset',
          class:'text-center',
          label:'Bill Type'
        },
        {
          key:'due_date',
          class:'text-center'
        },
        {
          key:'remarks',
          class:'text-center'
        },
        {
          key:'gl_date',
          class:'text-center'
        },
        {
          key:'tax_amount',
          class:'text-center'
        },
        {
          key:'value_date',
          class:'text-center'
        },
        {
          key:'complete_address',
          class:'text-center'
        },
        {
          key:'first_party_gstn',
          class:'text-center'
        },
        {
          key:'third_party_gstn',
          class:'text-center'
        },
        {
          key:'bill_status_vset',
          class:'text-center'
        },
        {
          key:'last_update_date',
          class:'text-center'
        },
        {
          key:'site_name',
          class:'text-center'
        },
        {
          key:'error_msg',
          class:'text-center'
        },
        {
          key:'hsn_code',
          class:'text-center'
        },
        {
          key:'complete_shipping_address',
          class:'text-center'
        },
        {
          key:'bill_date_from',
          class:'text-center'
        },
        {
          key:'sub_unit_area',
          class:'text-center'
        },
        {
          key:'bill_date_to',
          class:'text-center'
        }
      ],
      crystalBillData:[]
     };
  },
  mounted() {
    this.getCrystalBillData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fmsTransaction/getcrystalBillUploadById',
            'CrystalBillUpload',
            () => (this.loader = false)
          );
        }
      }
    });
    if (!this.rowData) {
      this.editMode = true;
    } else {
      this.getcrystalBillUploadById();
    }
  },
  methods: {
    getcrystalBillUploadById() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        batch_hdr_id: this.rowData.batch_crystal_hdr_id,
        meter_no:this.meterNumber,
        bill_no:this.billNumber,
        dg_status:this.dgStatus.value,
        elec_status:this.electricityStatus.value,
        oth_status:this.othersStatus.value};
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/getcrystalBillUploadById', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.crystalInputDtlsData = result;
            this.totalRows = resp.data.data.total_elements;
            this.autoFilledHdrDtls();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    autoFilledHdrDtls() {
      this.batchName = this.rowData.batch_name;
      this.billingPeriodTo = this.rowData.bill_date_to;
      this.billingPeriodFrom = this.rowData.bill_date_from;
      this.batchStatus = this.rowData.batch_status;
      this.projectName = this.rowData.prj_name;
    },
    validGenBatchData(type) {
      this.variantType = type;
      const payload = {
        template_name:
          type === 'validate'
            ? 'FMS_CRYSTAL_BILL_UPLOAD_PRC_temp'
            : 'FMS_GENERATE_CRYSTAL_BILL_PRC_temp'
      };
      this.$store
        .dispatch('template/getTemplateList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.templateId = response.data.data.page[0].template_id;
            this.requestId = response.data.data.page[0].request_id;
            if (this.templateId && this.requestId) {
              this.saveSubmitRequest();
            } else {
              this.$bvToast.toast('Something Went wrong. Please try again', {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap',
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK',
            no_of_args: 1,
            request_parameter: null,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: JSON.stringify(this.rowData.batch_crystal_hdr_id),
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest(this.variantType);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest(type) {
      if (type === 'validate') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus === 'COMPLETED')
                this.getcrystalBillUploadById();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else if (type === 'generate') {
        this.loader = true;
        this.$store
          .dispatch('template/getSubmitRequest', this.requestNumId)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.requestStatus1 = resp.data.data[0].status;
              this.requestNumId = resp.data.data[0].id;
              if (this.requestStatus1 === 'COMPLETED')
                this.getcrystalBillUploadById();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    openValueSetModal(vsetCode,type) {
      this.statusType =type
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.statusType === 'DG') {
        this.dgStatus.text = item.value_meaning;
        this.dgStatus.value = item.value_code;
      } else if (this.statusType === 'ELEC'){
        this.electricityStatus.text = item.value_meaning;
        this.electricityStatus.value = item.value_code;
      } else if (this.statusType === 'OTH'){
        this.othersStatus.text = item.value_meaning;
        this.othersStatus.value = item.value_code;
      }
    },
    clearVsetValues(type) {
      this.statusType = type;
      if (this.statusType === 'DG') {
        this.dgStatus.text = null;
        this.dgStatus.value = null;
      } else if (this.statusType === 'ELEC'){
        this.electricityStatus.text = null;
        this.electricityStatus.value = null;
      } else if (this.statusType === 'OTH'){
        this.othersStatus.text = null;
        this.othersStatus.value = null;
      }
    },
    clearFilters(){
      this.meterNumber = null;
      this.billNumber = null;
      this.dgStatus={
        text:null,
        value:null
      },
      this.electricityStatus = {
        text:null,
        value:null
      },
      this.othersStatus = {
        text:null,
        value:null
      },
      this.getcrystalBillUploadById();
    },
    getCrystalBillData() {
      this.payload = {
        billBatchId:this.rowData.batch_crystal_hdr_id
       };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/getCrystalBillData', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.crystalBillData = resp.data.data
            this.totalRows = resp.data.data.total_elements;
        }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadExcelData(){
      this.loader = true;
      const downloadpayload = { ...this.payload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        'fmsTransaction/getCrystalBillData',
        'CrystalBillData',
        () => (this.loader = false)
      );

    }

  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
