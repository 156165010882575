import ExcelDetails from './uploadExcelDetails';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../utility/url.utility';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'addUploadExcel',
  components: { ExcelDetails, appStrings , DatePicker},
  props: ['formName', 'propRecordHdrId', 'downloadType'],
  data() {
    return {
      showExcelDetailsModal: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showExcelModal: false,
      excelRowDtl: null,
      uploadExcelData: [],
      selectedFilesArr: [],
      uploadExcelFields: [
        {
          key: 's_no',
          label: 'Serial No.'
        },
        {
          key: 'excel_name'
        },
        {
          key: 'sheet_name'
        },
        {
          key: 'row_uploaded'
        },
        {
          key: 'mapped_column'
        },
        {
          key: 'updated_by'
        },
        {
          key: 'updation_date'
        },
        {
          key: 'delete'
        }
      ],
      showModal: true,
      recordId: null,
      downloadDocUrl: URL_UTILITY.getSampleFile,
      downloadPvJobDocUrl: URL_UTILITY.getPvJobSampleFile,
      downloadArCofDocUrl: URL_UTILITY.getArCofSampleFile,
      downloadDocumentUrl: URL_UTILITY.getAssetSampleFile,
      downloadVendorUploadDocumentUrl: URL_UTILITY.getVendorUploadSampleFile,
      downloadRfpItemDocumentUrl: URL_UTILITY.getRfpItemSampleFile,
      downloadRfpBoqDocumentUrl: URL_UTILITY.getRfpBoqSampleFile,
      downloadProvisionDocUrl: URL_UTILITY.getUploadProvisionSampleFileUrl,
      // downloadPanBankDocUrl: URL_UTILITY.getPanBankSampleFile,
      btnTitle: null,
      excelFormatFile: null,
      downloadExcelFormatUrl: URL_UTILITY.getGstManualDocumentUrl,
      excelFormatePRItcUpdationFile: appStrings.EXCELFORMATEPRITCUPDATION,
      itemType: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      excelImportHdrId: null,
      excelProcess:{
        value:null,
        text:null
      },
      downloadDashboardUploadDocumentUrl:URL_UTILITY.getDashboardDataUploadSampleFile,
      downloadDashboardTransferDocumentUrl:URL_UTILITY.getDashboardDataTransferSampleFile,
      projectName:{
        text:null,
        value:null
      },
      billingPeriodTo:null,
      billingPeriodFrom:null,
      openKmAuth: null,
      uploadDocList: [],
    };
  },
  validations: {
    itemType: {
      value: { required },
      text: { required }
    },
    excelProcess: {
      value: { required },
      text: { required }
    }
  },
  mounted() {
    this.recordId = this.propRecordHdrId;
    this.uploadFormater();
    if (this.$route.name === 'srchitcdataupdation') {
      this.btnTitle = 'Correction Invoice Uploader Format';
      this.excelFormatFile = appStrings.EXCELFORMATEINVOICESITCUPDATION;
    } else if (this.$route.name === 'srchItcRec') {
      this.btnTitle = 'Claim Unclaim Uploader';
      this.excelFormatFile = appStrings.EXCELFORMATEITCRECON;
    } else if (this.$route.name === 'salesRegsiter') {
      this.btnTitle = 'Correction Invoice Uploader Format';
      this.excelFormatFile = appStrings.EXCELFORMATESALESREGISTER;
    } else if (this.$route.name === 'vendorEmailSetup') {
      this.btnTitle = 'Email Setup Uploader';
      this.excelFormatFile = appStrings.EXCELFORMATEGSTEMAILSETUP;
    }

  },
  methods: {
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      this.saveFileToTempTable(this.$refs.file.files.item(0));
      this.uploadDocList.push({
        fileRef: this.$refs.file.files,
        file_name: this.$refs.file.files[0].name,
        leId: null,
        leName: null,
        categoryCode: null,
        categoryName: null,
        subcategoryCode: null,
        subcategoryName: null,
        comments: '',
        version: 0,
        file_size: this.formatBytes(this.$refs.file.files[0].size),
        action: null,
        docDetailId: 0
      });
    },
    browseFile() {
      this.$refs.file.click();
    },
    arcofFileUploadFunc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/excelUploadData', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    timeSheetFileUploadFunc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/excelUploadData', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    timesheetSummaryFileUploadFunc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch(
          'fmsTimesheet/timsheetSummaryexcelUploadData',
          excel_import_hdr_id
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    nfaApprovalFileUploadFunc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/excelUploadDetails', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    iTcDataFileUploadFunc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('itcDataUpdation/uploadDataUpdation', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadSalesRegister() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch(
          'itcDataUpdation/uploadExcelSalesRegiater',
          excel_import_hdr_id
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadVendorEmailSetup() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('gstMaster/uploadExcelVendorEmail', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadItcReconBooks() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch(
          'itcReconciliation/excelUploadItcReconBooks',
          excel_import_hdr_id
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    receiptAccUploadExcelFunc() {
      const payload = {
        excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/receiptAccUploadExcelFunc', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    twoSixAsFileUploadFunc() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id:
          this.formName === 'UNPROCESSED_RECOVERY' ||
          this.downloadType === 'distribution'
            ? 1
            : 0
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/postTempDataList', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    uploadGstPurchaseRegister() {
      const payload = {
        excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/uploadGstPurchaseRegister', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    partyVerificationFileUploadFun() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('gstrTwoADownload/uploadDataUpdation', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    importExcelUpload() {},
    saveExcelUpload() {},
    closeExcelUpload() {
      this.showExcelModal = false;
    },
    saveFileToTempTable(fileName) {
      let formData = new FormData();
      formData.append('file', fileName);
      formData.append('menu_id', this.$store.state.shared.menuId);
      formData.append('sub_menu_id', 0);
      if (this.recordId) {
        formData.append('record_id', this.recordId ? this.recordId : null);
      }
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/addUploadExcelList', formData)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            const results = response.data.data;
            this.excelImportHdrId = results[0].excel_improt_hdr_id;
            if (this.formName === 'NFA_APPROVAL') {
              this.uploadExcelData = results.splice(0, 1);
            } else {
              this.uploadExcelData = results;
            }
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (this.formName === 'INVOICE_APPROVAL') {
              if (this.downloadType === 'cost_centre') {
                this.validationInvoiceApproval();
              }
            }
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateLocationFileUploadFunc() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('assets/addUploadAssetExcelList', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadDataApInvoiceEntry() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('payable/excelUploadDataApInvoiceEntry', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    vendorHoldMappingExcelUpload() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('gstMaster/vendorHoldMappingExcelUpload', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    itcReconGstrTwo6AExcelUpload() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/itcReconGstrTwo6AExcelUpload', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    uploadExcelParty() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('party/uploadExcelParty', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    billUploadDoc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/billUploadDoc', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    applyReceiptUploadDoc() {
      const payload = {
        excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/applyReceiptUploadDoc', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      },
      uploadProvisionData(){
        const payload = {
          excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
        };
        this.loader = true;
        this.$store
          .dispatch('provisionsAdmin/uploadProvisionData', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
    },
    fmsMeterUploadDoc() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('masterData/fmsMeterUploadDoc', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
           this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    uploadDataItcReco() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/uploadDataItcReco', excel_import_hdr_id)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadEnergyDataInput() {
      const excel_import_hdr_id = this.uploadExcelData[0].excel_improt_hdr_id;
      this.loader = true;
      this.$store
        .dispatch(
          'energyDataInput/excelUploadEnergyDataInput',
          excel_import_hdr_id
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    uploadCarryForwardData(){
      const payload = {
        excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/uploadCarryForwardData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    uploadCarryForwardSearchData(){
      const payload = {
        excel_import_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/uploadCarryForwardSearchData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    downloadFile() {},
    removeRow(index) {
      this.uploadExcelData.splice(index, 1);
    },
    showExcelDetails(flag, rowData, index) {
      this.showExcelDetailsModal = flag;
      this.excelRowDtl = rowData;
      this.sheetIndex = index;
    },
    closeExcelDetail(columns) {
      if (columns || columns === 0) {
        this.uploadExcelData[this.sheetIndex].mapped_column = columns;
      }
      this.showExcelDetails(false);
    },
    uploadFiles() {
      if (this.uploadExcelData.length === 0) {
        alert('Please add file');
      } else {
        switch (this.formName) {
          case 'ITC_DATA_UPDATION':
            this.iTcDataFileUploadFunc();
            break;
          case 'RECEIPT_ACC':
            this.receiptAccUploadExcelFunc();
            break;
          case 'TWO_SIX_AS_UPLOAD':
          case 'ASSET_TRANSFER':
          case 'PAN_BANK_VERIFICATION':
          case 'PV_JOB':
          case 'UNPROCESSED_RECOVERY':
          case 'INVOICE_APPROVAL':
            this.twoSixAsFileUploadFunc();
            break;
          case 'NFA_APPROVAL':
            this.nfaApprovalFileUploadFunc();
            break;
          case 'UPDATE_LOCATION':
            this.updateLocationFileUploadFunc();
            break;
          case 'AR_COF':
            this.arcofFileUploadFunc();
            break;
          case 'PARTY_VERIFICATION':
            this.partyVerificationFileUploadFun();
            break;
          case 'TIME_SHEET':
            this.timeSheetFileUploadFunc();
            break;
          case 'TIMESHEET_SUMMARY':
            this.timesheetSummaryFileUploadFunc();
            break;
          case 'SEARCH_ASSET':
            this.twoSixAsFileUploadFunc();
            break;
          case 'AP_INVOICE_ENTRY':
            this.excelUploadDataApInvoiceEntry();
            break;
          case 'SALES_REGISTER':
            this.excelUploadSalesRegister();
            break;
          case 'VENDOR_EMAIL_SETUP':
            this.excelUploadVendorEmailSetup();
            break;
          case 'ITC_RECON_BOOKS':
            this.excelUploadItcReconBooks();
            break;
          case 'ASSET_INBOUND':
            this.assetInboundExcelUpload();
            break;
          case 'GL_PARTY':
            this.uploadExcelParty();
            break;
          case 'BILL_UPLOAD':
            this.billUploadDoc();
            break;
          case 'RFPitem':
            this.excelUploadRfp();
            break;
          case 'RFPboq':
            this.excelUploadRfp();
            break;
          case 'VENDOR_HOLD_MAPPING':
            this.vendorHoldMappingExcelUpload();
            break;
          case 'ITC_RECON_GSTR_6A':
            this.itcReconGstrTwo6AExcelUpload();
            break;
          case 'APPLY_RECEIPT_UPLOAD':
            this.applyReceiptUploadDoc();
            break;  
          case 'UPLOAD_PROVISIONS':
            this.uploadProvisionData();
            break;
          case 'VENDOR_UPLOAD':
            this.vendorOnboardExcelUpload();
            break;
          case 'METER_UPLOAD':
            this.fmsMeterUploadDoc();
            break;
          case 'ITC_RECO_IMS':
            this.uploadDataItcReco();
            break;
          case 'ENERGY_DATA_INPUT':
            this.excelUploadEnergyDataInput();
            break;
          case 'CARRY_FORWARD':
            this.uploadCarryForwardData();
            break;  
          case 'CARRY_FORWARD_SEARCH':
            this.uploadCarryForwardSearchData();
            break; 
          case 'ASSET_DASHBOARD' :
            this.excelProcess.text=='TRANSFER'?this.assetDashboardExcelTransfer():this.assetDashboardExcelUpload();
            break;
            case 'CRYSTAL_UPLOAD' :
              this.crystalBillUpload();
            break;
        }
      }
    },
    hideExcelUploadModal() {
      this.showModal = false;
      this.$emit('closeUploadExcelModal');
    },
    assetInboundExcelUpload() {
      const payload = {
        excel_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        loc_id: this.propRecordHdrId,
        item_type: this.itemType.text
      };
      this.loader = true;
      this.$store
        .dispatch('assets/assetInboundExcelUpload', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },

    openValueSetModal(vsetCode) {
      if(this.formName=='ASSET_DASHBOARD' && this.excelProcess.text==null && vsetCode=='FA_ITEM_TYPE' ){
        this.$bvToast.toast('Kindly select excel process', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else{
        this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
      }
      
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_TYPE) {
        this.itemType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if(this.vsetCode === appStrings.VALUESETTYPE.EXCEL_PROCESS){
        this.excelProcess={
          value: item.value_code,
          text: item.value_meaning
        }
        this.itemType = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT){
        this.projectName ={
          text: item.value_code,
          value: item.value_set_dtl_id
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode == 'FA_ITEM_TYPE') {
        this.itemType = {
          text: null,
          value: null
        };
      } else if (vsetCode == 'EXCEL_PROCESS') {
        this.excelProcess = {
          text: null,
          value: null
        };
        this.itemType = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'FMS_PROJECT'){
        this.projectName ={
          text :null,
          value :null
        }
      }
    },
    validationInvoiceApproval() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/validationInvoiceApproval', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    validateUploadProvision() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/validateUploadProvision', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.data;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    excelUploadRfp() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: this.formName === 'RFPboq' ? 86 : 0
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/addUploadRfpExcelList', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if(this.formName === 'RFPboq') {
              this.getOpenKmAuthToken();
            }
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    manualReconFormatDown() {
      this.$emit('manualRecoDowmload');
    },
    uploadFormater() {
      if (this.$route.name === 'srchitcdataupdation') {
        this.btnTitle = 'Correction Invoice Uploader Format';
        this.excelFormatFile = appStrings.EXCELFORMATEINVOICESITCUPDATION;
      } else if (this.$route.name === 'srchItcRec') {
        if (this.formName === 'ITC_RECON_BOOKS') {
          this.btnTitle = 'Claim Unclaim Uploader';
          this.excelFormatFile = appStrings.EXCELFORMATEITCRECON;
        }
      } else if (this.$route.name === 'salesRegsiter') {
        this.btnTitle = 'Correction Invoice Uploader Format';
        this.excelFormatFile = appStrings.EXCELFORMATESALESREGISTER;
      } else if (this.$route.name === 'vendorEmailSetup') {
        this.btnTitle = 'Email Setup Uploader';
        this.excelFormatFile = appStrings.EXCELFORMATEGSTEMAILSETUP;
      } else if (this.$route.name === 'vendorHoldMapping') {
        this.btnTitle = 'Hold Unhold Uploader';
        this.excelFormatFile = appStrings.EXCELFORMATEVENDERHOLDMAPPING;
      }
    },
    vendorOnboardExcelUpload() {
      const payload = {
        excelImportId: this.uploadExcelData[0].excel_improt_hdr_id,
      };
      this.loader = true;
      this.$store
        .dispatch('party/postVendorOnboardExcel', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    assetDashboardExcelUpload(){
      const payload = {
        excelHdrId: this.uploadExcelData[0].excel_improt_hdr_id,
        itemType:this.itemType.text
      };
      this.loader = true;
      this.$store
        .dispatch('assets/assetDashboardExcelUpload', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    assetDashboardExcelTransfer(){
      const payload = {
        excelHdrId: this.uploadExcelData[0].excel_improt_hdr_id,
        itemType:this.itemType.text
      };
      this.loader = true;
      this.$store
        .dispatch('assets/assetDashboardExcelTransfer', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    crystalBillUpload() {
      if (this.projectName.text === null || this.billingPeriodFrom === null || this.billingPeriodTo === null){
        this.$bvToast.toast('Please Select Mandatory Fields', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else{
        const payload ={
          excelImportId: this.uploadExcelData[0].excel_improt_hdr_id,
            prj_id : this.projectName.value,
            billing_period_from : this.billingPeriodFrom,
            billing_period_to : this.billingPeriodTo
          }
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/crystalBillUpload', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
        }

    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.saveExcelDownload();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveExcelDownload() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openKmAuth);
          formData.append('category', null);
          formData.append('sub_category', null);
          formData.append('comment', "");
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.$store.state.shared.menuId);
          formData.append('sub_menu_id', 90);
          formData.append('le_id', null);
          formData.append('record_id', this.recordId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      } 
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
